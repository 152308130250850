export function select_on(num_clicks) {
  function handler(event) {
    if (event.detail === num_clicks) {
      const selection = window.getSelection();
      if (!selection)
        return;
      selection.removeAllRanges();
      const range = document.createRange();
      range.selectNodeContents(event.target);
      selection.addRange(range);
    }
  }
  return handler;
}
