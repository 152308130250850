import './App.svelte.css';
/* src/App.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	mount_component,
	null_to_empty,
	safe_not_equal,
	set_store_value,
	space,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import { onMount } from '../_snowpack/pkg/svelte.js';
import { location as _location, motion } from './stores.js';
import ToggleMotion from './components/ToggleMotion.svelte.js';
import Home from './Home.svelte.js';
import PGP from './pages/PGP.svelte.js';
import Projects from './pages/Projects.svelte.js';
import Accounts from './pages/Accounts.svelte.js';
import About from './pages/About.svelte.js';
import Canary from './components/Canary.svelte.js';
import CanaryText from './pages/Canary.svelte.js';

function create_else_block(ctx) {
	let main;
	let current_block_type_index;
	let if_block;
	let t0;
	let togglemotion;
	let t1;
	let canary;
	let main_class_value;
	let current;

	const if_block_creators = [
		create_if_block_1,
		create_if_block_2,
		create_if_block_3,
		create_if_block_4,
		create_if_block_5,
		create_else_block_1
	];

	const if_blocks = [];

	function select_block_type_1(ctx, dirty) {
		if (/*$_location*/ ctx[0] === 'projects') return 0;
		if (/*$_location*/ ctx[0] === 'pgp') return 1;
		if (/*$_location*/ ctx[0] === 'accounts') return 2;
		if (/*$_location*/ ctx[0] === 'about') return 3;
		if (/*$_location*/ ctx[0] === 'canary') return 4;
		return 5;
	}

	current_block_type_index = select_block_type_1(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
	togglemotion = new ToggleMotion({});
	canary = new Canary({});

	return {
		c() {
			main = element("main");
			if_block.c();
			t0 = space();
			create_component(togglemotion.$$.fragment);
			t1 = space();
			create_component(canary.$$.fragment);
			attr(main, "class", main_class_value = "" + (null_to_empty(/*$motion*/ ctx[1]) + " svelte-1sx4lh8"));
			attr(main, "data-testid", "main");
		},
		m(target, anchor) {
			insert(target, main, anchor);
			if_blocks[current_block_type_index].m(main, null);
			append(main, t0);
			mount_component(togglemotion, main, null);
			append(main, t1);
			mount_component(canary, main, null);
			current = true;
		},
		p(ctx, dirty) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type_1(ctx, dirty);

			if (current_block_type_index !== previous_block_index) {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					
				}

				transition_in(if_block, 1);
				if_block.m(main, t0);
			}

			if (!current || dirty & /*$motion*/ 2 && main_class_value !== (main_class_value = "" + (null_to_empty(/*$motion*/ ctx[1]) + " svelte-1sx4lh8"))) {
				attr(main, "class", main_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			transition_in(togglemotion.$$.fragment, local);
			transition_in(canary.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			transition_out(togglemotion.$$.fragment, local);
			transition_out(canary.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(main);
			if_blocks[current_block_type_index].d();
			destroy_component(togglemotion);
			destroy_component(canary);
		}
	};
}

// (33:0) {#if $_location === 'canary'}
function create_if_block(ctx) {
	let main;
	let canarytext;
	let t0;
	let togglemotion;
	let t1;
	let canary;
	let main_class_value;
	let current;
	canarytext = new CanaryText({});
	togglemotion = new ToggleMotion({});
	canary = new Canary({});

	return {
		c() {
			main = element("main");
			create_component(canarytext.$$.fragment);
			t0 = space();
			create_component(togglemotion.$$.fragment);
			t1 = space();
			create_component(canary.$$.fragment);
			attr(main, "class", main_class_value = "" + (null_to_empty(/*$motion*/ ctx[1]) + " svelte-1sx4lh8"));
			attr(main, "data-testid", "main");
		},
		m(target, anchor) {
			insert(target, main, anchor);
			mount_component(canarytext, main, null);
			append(main, t0);
			mount_component(togglemotion, main, null);
			append(main, t1);
			mount_component(canary, main, null);
			current = true;
		},
		p(ctx, dirty) {
			if (!current || dirty & /*$motion*/ 2 && main_class_value !== (main_class_value = "" + (null_to_empty(/*$motion*/ ctx[1]) + " svelte-1sx4lh8"))) {
				attr(main, "class", main_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(canarytext.$$.fragment, local);
			transition_in(togglemotion.$$.fragment, local);
			transition_in(canary.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(canarytext.$$.fragment, local);
			transition_out(togglemotion.$$.fragment, local);
			transition_out(canary.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(main);
			destroy_component(canarytext);
			destroy_component(togglemotion);
			destroy_component(canary);
		}
	};
}

// (51:2) {:else}
function create_else_block_1(ctx) {
	let home;
	let current;
	home = new Home({});

	return {
		c() {
			create_component(home.$$.fragment);
		},
		m(target, anchor) {
			mount_component(home, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(home.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(home.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(home, detaching);
		}
	};
}

// (49:36) 
function create_if_block_5(ctx) {
	let canarytext;
	let current;
	canarytext = new CanaryText({});

	return {
		c() {
			create_component(canarytext.$$.fragment);
		},
		m(target, anchor) {
			mount_component(canarytext, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(canarytext.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(canarytext.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(canarytext, detaching);
		}
	};
}

// (47:35) 
function create_if_block_4(ctx) {
	let about;
	let current;
	about = new About({});

	return {
		c() {
			create_component(about.$$.fragment);
		},
		m(target, anchor) {
			mount_component(about, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(about.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(about.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(about, detaching);
		}
	};
}

// (45:38) 
function create_if_block_3(ctx) {
	let accounts;
	let current;
	accounts = new Accounts({});

	return {
		c() {
			create_component(accounts.$$.fragment);
		},
		m(target, anchor) {
			mount_component(accounts, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(accounts.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(accounts.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(accounts, detaching);
		}
	};
}

// (43:33) 
function create_if_block_2(ctx) {
	let pgp;
	let current;
	pgp = new PGP({});

	return {
		c() {
			create_component(pgp.$$.fragment);
		},
		m(target, anchor) {
			mount_component(pgp, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(pgp.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(pgp.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(pgp, detaching);
		}
	};
}

// (41:2) {#if $_location === 'projects'}
function create_if_block_1(ctx) {
	let projects;
	let current;
	projects = new Projects({});

	return {
		c() {
			create_component(projects.$$.fragment);
		},
		m(target, anchor) {
			mount_component(projects, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(projects.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(projects.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(projects, detaching);
		}
	};
}

function create_fragment(ctx) {
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;
	const if_block_creators = [create_if_block, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*$_location*/ ctx[0] === 'canary') return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_blocks[current_block_type_index].m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(if_block_anchor.parentNode, if_block_anchor);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if_blocks[current_block_type_index].d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $_location;
	let $motion;
	component_subscribe($$self, _location, $$value => $$invalidate(0, $_location = $$value));
	component_subscribe($$self, motion, $$value => $$invalidate(1, $motion = $$value));

	onMount(() => {
		if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
			set_store_value(motion, $motion = '', $motion);
		}

		window.onhashchange = () => {
			switch (window.location.hash) {
				case '#home':
				case '#about':
				case '#projects':
				case '#accounts':
				case '#pgp':
				case '#canary':
					set_store_value(_location, $_location = window.location.hash.substring(1), $_location);
					break;
				default:
					set_store_value(_location, $_location = 'home', $_location);
			}
		};

		set_store_value(_location, $_location = window.location.hash.substring(1) || 'home', $_location);
	});

	return [$_location, $motion];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default App;