import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';
import.meta.env = __SNOWPACK_ENV__;

import App from "./App.svelte.js";
const app = new App({
  target: document.body
});
const colors = [
  "peach",
  "strawberry",
  "cantaloupe",
  "banana",
  "watermelon",
  "mint",
  "water",
  "ube"
];
const color = colors[Math.floor(Math.random() * colors.length)];
document.documentElement.style.setProperty("--accent", `var(--${color})`);
export default app;
if (undefined /* [snowpack] import.meta.hot */ ) {
  undefined /* [snowpack] import.meta.hot */ .accept();
  undefined /* [snowpack] import.meta.hot */ .dispose(() => {
    app.$destroy();
  });
}
