import './Canary.svelte.css';
/* src/pages/Canary.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	create_in_transition,
	create_out_transition,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal
} from "../../_snowpack/pkg/svelte/internal.js";

import { fly } from '../../_snowpack/pkg/svelte/transition.js';
import { select_on } from './selection.js';
import canary_text from '../canary.js';

function create_fragment(ctx) {
	let main;
	let div;
	let pre;
	let main_intro;
	let main_outro;
	let current;

	return {
		c() {
			main = element("main");
			div = element("div");
			pre = element("pre");
			attr(pre, "class", "svelte-1x6mi9g");
			attr(div, "class", "canary-text svelte-1x6mi9g");
			attr(main, "class", "svelte-1x6mi9g");
		},
		m(target, anchor) {
			insert(target, main, anchor);
			append(main, div);
			append(div, pre);
			pre.innerHTML = /*transformed*/ ctx[0];
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;

			add_render_callback(() => {
				if (!current) return;
				if (main_outro) main_outro.end(1);
				main_intro = create_in_transition(main, fly, { x: -600, duration: 400 });
				main_intro.start();
			});

			current = true;
		},
		o(local) {
			if (main_intro) main_intro.invalidate();
			main_outro = create_out_transition(main, fly, { x: -600, duration: 400 });
			current = false;
		},
		d(detaching) {
			if (detaching) detach(main);
			if (detaching && main_outro) main_outro.end();
		}
	};
}

function instance($$self) {
	var _a;
	const transformed = canary_text.replace(/(https?:\/\/.+\.asc)/, '<a href="$1">$1</a>');

	(_a = document.querySelector('pre a')) === null || _a === void 0
	? void 0
	: _a.addEventListener('click', select_on(2));

	return [transformed];
}

class Canary extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Canary;