import './About.svelte.css';
/* src/pages/About.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	create_component,
	create_in_transition,
	create_out_transition,
	destroy_component,
	detach,
	element,
	init,
	insert,
	listen,
	mount_component,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	space,
	svg_element,
	text,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import { fly, draw } from '../../_snowpack/pkg/svelte/transition.js';
import { quintInOut } from '../../_snowpack/pkg/svelte/easing.js';
import { mdiBookOpenPageVariant, mdiEmail, mdiHeartPulse, mdiKey } from '../../_snowpack/pkg/@mdi/js.js';
import Back from '../components/Back.svelte.js';

function create_else_block(ctx) {
	let span;
	let p;
	let a;
	let t;
	let a_href_value;
	let span_intro;

	return {
		c() {
			span = element("span");
			p = element("p");
			a = element("a");
			t = text(/*email*/ ctx[0]);
			attr(a, "href", a_href_value = "mailto:" + /*email*/ ctx[0]);
			attr(a, "class", "svelte-d273ck");
		},
		m(target, anchor) {
			insert(target, span, anchor);
			append(span, p);
			append(p, a);
			append(a, t);
		},
		p(ctx, dirty) {
			if (dirty & /*email*/ 1) set_data(t, /*email*/ ctx[0]);

			if (dirty & /*email*/ 1 && a_href_value !== (a_href_value = "mailto:" + /*email*/ ctx[0])) {
				attr(a, "href", a_href_value);
			}
		},
		i(local) {
			if (!span_intro) {
				add_render_callback(() => {
					span_intro = create_in_transition(span, fly, { delay: 100, duration: 400 });
					span_intro.start();
				});
			}
		},
		o: noop,
		d(detaching) {
			if (detaching) detach(span);
		}
	};
}

// (35:4) {#if !email}
function create_if_block(ctx) {
	let button;
	let svg;
	let path;
	let t;
	let mounted;
	let dispose;

	return {
		c() {
			button = element("button");
			svg = svg_element("svg");
			path = svg_element("path");
			t = text("\n        Click to reveal email");
			attr(path, "d", mdiEmail);
			attr(svg, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg, "viewBox", "0 0 24 24");
			attr(svg, "width", "24px");
			attr(svg, "height", "24px");
			attr(svg, "class", "svelte-d273ck");
			attr(button, "class", "email svelte-d273ck");
		},
		m(target, anchor) {
			insert(target, button, anchor);
			append(button, svg);
			append(svg, path);
			append(button, t);

			if (!mounted) {
				dispose = listen(button, "click", /*click_handler*/ ctx[6]);
				mounted = true;
			}
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let main;
	let div;
	let h2;
	let t1;
	let h3;
	let t3;
	let p0;
	let t5;
	let p1;
	let t7;
	let p2;
	let t11;
	let p3;
	let t13;
	let p4;
	let t17;
	let t18;
	let button0;
	let svg0;
	let path0;
	let t19;
	let t20;
	let button1;
	let svg1;
	let path1;
	let t21;
	let t22;
	let button2;
	let svg2;
	let path2;
	let t23;
	let t24;
	let back;
	let main_intro;
	let main_outro;
	let t25;
	let svg3;
	let g0;
	let path3;
	let path3_intro;
	let path4;
	let path4_intro;
	let path5;
	let path5_intro;
	let path6;
	let path6_intro;
	let svg3_outro;
	let t26;
	let svg4;
	let g1;
	let path7;
	let path7_intro;
	let path8;
	let path8_intro;
	let path9;
	let path9_intro;
	let path10;
	let path10_intro;
	let path11;
	let path11_intro;
	let svg4_outro;
	let current;
	let mounted;
	let dispose;

	function select_block_type(ctx, dirty) {
		if (!/*email*/ ctx[0]) return create_if_block;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);
	back = new Back({});

	return {
		c() {
			main = element("main");
			div = element("div");
			h2 = element("h2");
			h2.textContent = "About";
			t1 = space();
			h3 = element("h3");
			h3.textContent = "Hi!";
			t3 = space();
			p0 = element("p");
			p0.textContent = "I'm Isis. You might know me by another name, I have quite a few.";
			t5 = space();
			p1 = element("p");
			p1.innerHTML = `<a href="https://pronouns.5ht2.me/they?or=she" class="svelte-d273ck">they/she</a>`;
			t7 = space();
			p2 = element("p");

			p2.textContent = `I'm a musician, activist, and developer. I've been coding for around ${/*yearsOfCoding*/ ctx[4]}
      years now and still striving to learn more. Currently, I'm fairly proficient in Python, JavaScript,
      Rust, Go, Java, C, SQL, and (not a lang, but) Shell.`;

			t11 = space();
			p3 = element("p");
			p3.textContent = "Apart from these, I am conversationally fluent in 9 languages, and can follow many more to a\n      limited extent. In spite of this, I don't really talk much.";
			t13 = space();
			p4 = element("p");

			p4.innerHTML = `I love reading, and if you&#39;re feeling generous, consider buying me something from my
      <a href="https://www.amazon.co.uk/hz/wishlist/ls/3NJTN6NMRKMU0" class="svelte-d273ck">wishlist</a> or help fund my habit
      through the link below 🥺`;

			t17 = space();
			if_block.c();
			t18 = space();
			button0 = element("button");
			svg0 = svg_element("svg");
			path0 = svg_element("path");
			t19 = text("\n      Heartbeat");
			t20 = space();
			button1 = element("button");
			svg1 = svg_element("svg");
			path1 = svg_element("path");
			t21 = text("\n      SSH Keys");
			t22 = space();
			button2 = element("button");
			svg2 = svg_element("svg");
			path2 = svg_element("path");
			t23 = text("\n      Fund my reading habit");
			t24 = space();
			create_component(back.$$.fragment);
			t25 = space();
			svg3 = svg_element("svg");
			g0 = svg_element("g");
			path3 = svg_element("path");
			path4 = svg_element("path");
			path5 = svg_element("path");
			path6 = svg_element("path");
			t26 = space();
			svg4 = svg_element("svg");
			g1 = svg_element("g");
			path7 = svg_element("path");
			path8 = svg_element("path");
			path9 = svg_element("path");
			path10 = svg_element("path");
			path11 = svg_element("path");
			attr(path0, "d", mdiHeartPulse);
			attr(svg0, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg0, "viewBox", "0 0 24 24");
			attr(svg0, "width", "24px");
			attr(svg0, "height", "24px");
			attr(svg0, "class", "svelte-d273ck");
			attr(button0, "class", "svelte-d273ck");
			attr(path1, "d", mdiKey);
			attr(svg1, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg1, "viewBox", "0 0 24 24");
			attr(svg1, "width", "24px");
			attr(svg1, "height", "24px");
			attr(svg1, "class", "svelte-d273ck");
			attr(button1, "class", "svelte-d273ck");
			attr(path2, "d", mdiBookOpenPageVariant);
			attr(svg2, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg2, "viewBox", "0 0 24 24");
			attr(svg2, "width", "24px");
			attr(svg2, "height", "24px");
			attr(svg2, "class", "svelte-d273ck");
			attr(button2, "class", "svelte-d273ck");
			attr(div, "class", "svelte-d273ck");
			attr(main, "class", "svelte-d273ck");
			attr(path3, "stroke", "#FFF433");
			attr(path3, "d", "M.132.132h26.459");
			attr(path4, "stroke", "#9B59D0");
			attr(path4, "stroke-linejoin", "round");
			attr(path4, "d", "M53.049.132h26.458");
			attr(path5, "stroke", "#000");
			attr(path5, "d", "M79.507.132h26.459");
			attr(path6, "stroke", "#fff");
			attr(path6, "stroke-linejoin", "round");
			attr(path6, "d", "M26.59.132H53.05");
			attr(g0, "fill", "none");
			attr(g0, "stroke-width", "10");
			attr(svg3, "class", "enby svelte-d273ck");
			attr(svg3, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg3, "viewBox", "0 0 106.098 0.5");
			attr(path7, "stroke", "#5BCFFA");
			attr(path7, "d", "M.132.132h26.459");
			attr(path8, "stroke", "#F5ABB9");
			attr(path8, "stroke-linejoin", "round");
			attr(path8, "d", "M26.59.132H53.05");
			attr(path9, "stroke", "#FFF");
			attr(path9, "stroke-linejoin", "round");
			attr(path9, "d", "M53.049.132h26.458");
			attr(path10, "stroke", "#F5ABB9");
			attr(path10, "d", "M79.507.132h26.459");
			attr(path11, "stroke", "#5BCFFA");
			attr(path11, "d", "M105.966.132h26.458");
			attr(g1, "fill", "none");
			attr(g1, "stroke-width", "10");
			attr(svg4, "class", "trans svelte-d273ck");
			attr(svg4, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg4, "viewBox", "0 0 132.292 0.5");
		},
		m(target, anchor) {
			insert(target, main, anchor);
			append(main, div);
			append(div, h2);
			append(div, t1);
			append(div, h3);
			append(div, t3);
			append(div, p0);
			append(div, t5);
			append(div, p1);
			append(div, t7);
			append(div, p2);
			append(div, t11);
			append(div, p3);
			append(div, t13);
			append(div, p4);
			append(div, t17);
			if_block.m(div, null);
			append(div, t18);
			append(div, button0);
			append(button0, svg0);
			append(svg0, path0);
			append(button0, t19);
			append(div, t20);
			append(div, button1);
			append(button1, svg1);
			append(svg1, path1);
			append(button1, t21);
			append(div, t22);
			append(div, button2);
			append(button2, svg2);
			append(svg2, path2);
			append(button2, t23);
			append(div, t24);
			mount_component(back, div, null);
			insert(target, t25, anchor);
			insert(target, svg3, anchor);
			append(svg3, g0);
			append(g0, path3);
			append(g0, path4);
			append(g0, path5);
			append(g0, path6);
			insert(target, t26, anchor);
			insert(target, svg4, anchor);
			append(svg4, g1);
			append(g1, path7);
			append(g1, path8);
			append(g1, path9);
			append(g1, path10);
			append(g1, path11);
			current = true;

			if (!mounted) {
				dispose = [
					listen(button0, "click", /*click_handler_1*/ ctx[7]),
					listen(button1, "click", /*click_handler_2*/ ctx[8]),
					listen(button2, "click", /*click_handler_3*/ ctx[9])
				];

				mounted = true;
			}
		},
		p(new_ctx, [dirty]) {
			ctx = new_ctx;

			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(div, t18);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			transition_in(back.$$.fragment, local);

			add_render_callback(() => {
				if (!current) return;
				if (main_outro) main_outro.end(1);
				main_intro = create_in_transition(main, fly, { x: 600, duration: 400 });
				main_intro.start();
			});

			if (!path3_intro) {
				add_render_callback(() => {
					path3_intro = create_in_transition(path3, draw, /*enbyIn*/ ctx[1]);
					path3_intro.start();
				});
			}

			if (!path4_intro) {
				add_render_callback(() => {
					path4_intro = create_in_transition(path4, draw, /*enbyIn*/ ctx[1]);
					path4_intro.start();
				});
			}

			if (!path5_intro) {
				add_render_callback(() => {
					path5_intro = create_in_transition(path5, draw, /*enbyIn*/ ctx[1]);
					path5_intro.start();
				});
			}

			if (!path6_intro) {
				add_render_callback(() => {
					path6_intro = create_in_transition(path6, draw, /*enbyIn*/ ctx[1]);
					path6_intro.start();
				});
			}

			if (svg3_outro) svg3_outro.end(1);

			if (!path7_intro) {
				add_render_callback(() => {
					path7_intro = create_in_transition(path7, draw, /*transIn*/ ctx[2]);
					path7_intro.start();
				});
			}

			if (!path8_intro) {
				add_render_callback(() => {
					path8_intro = create_in_transition(path8, draw, /*transIn*/ ctx[2]);
					path8_intro.start();
				});
			}

			if (!path9_intro) {
				add_render_callback(() => {
					path9_intro = create_in_transition(path9, draw, /*transIn*/ ctx[2]);
					path9_intro.start();
				});
			}

			if (!path10_intro) {
				add_render_callback(() => {
					path10_intro = create_in_transition(path10, draw, /*transIn*/ ctx[2]);
					path10_intro.start();
				});
			}

			if (!path11_intro) {
				add_render_callback(() => {
					path11_intro = create_in_transition(path11, draw, /*transIn*/ ctx[2]);
					path11_intro.start();
				});
			}

			if (svg4_outro) svg4_outro.end(1);
			current = true;
		},
		o(local) {
			transition_out(back.$$.fragment, local);
			if (main_intro) main_intro.invalidate();
			main_outro = create_out_transition(main, fly, { x: 600, duration: 600 });
			svg3_outro = create_out_transition(svg3, fly, /*flagsOut*/ ctx[3]);
			svg4_outro = create_out_transition(svg4, fly, /*flagsOut*/ ctx[3]);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(main);
			if_block.d();
			destroy_component(back);
			if (detaching && main_outro) main_outro.end();
			if (detaching) detach(t25);
			if (detaching) detach(svg3);
			if (detaching && svg3_outro) svg3_outro.end();
			if (detaching) detach(t26);
			if (detaching) detach(svg4);
			if (detaching && svg4_outro) svg4_outro.end();
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	const enbyIn = {
		duration: 800,
		easing: quintInOut,
		delay: 100
	};

	const transIn = {
		duration: 800,
		easing: quintInOut,
		delay: 500
	};

	const flagsOut = { duration: 200, easing: quintInOut };
	const now = new Date().getTime();
	const yearsSince = new Date('2016-01-01').getTime();
	const yearsOfCoding = Math.floor(Math.floor(Math.floor((now - yearsSince) / 60000 / 60) / 24) / 365).toString();
	let email = '';
	let uncomputedEmail = [['i5ht2', 'pm'].join('@'), 'me'].join('.');
	const click_handler = () => $$invalidate(0, email = uncomputedEmail);
	const click_handler_1 = () => window.open('https://hb.5ht2.me');
	const click_handler_2 = () => window.open('https://api.5ht2.me/me.keys');
	const click_handler_3 = () => window.open('https://buymeacoffee.com/lmaotrigine');

	return [
		email,
		enbyIn,
		transIn,
		flagsOut,
		yearsOfCoding,
		uncomputedEmail,
		click_handler,
		click_handler_1,
		click_handler_2,
		click_handler_3
	];
}

class About extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default About;