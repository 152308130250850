import './ToggleMotion.svelte.css';
/* src/components/ToggleMotion.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	attr,
	component_subscribe,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	run_all,
	safe_not_equal,
	set_store_value
} from "../../_snowpack/pkg/svelte/internal.js";

import { motion } from '../stores.js';

function create_fragment(ctx) {
	let div;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");

			div.innerHTML = `<pre>      _
    _( )_
  _(     )_
 (_________)
   \\  \\  \\
     \\  \\</pre>`;

			attr(div, "id", "rain");
			attr(div, "class", "svelte-1mgl5a9");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (!mounted) {
				dispose = [
					listen(div, "click", /*toggle*/ ctx[0]),
					listen(div, "keypress", /*toggle*/ ctx[0])
				];

				mounted = true;
			}
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $motion;
	component_subscribe($$self, motion, $$value => $$invalidate(1, $motion = $$value));

	function toggle() {
		if ($motion) {
			set_store_value(motion, $motion = '', $motion);
		} else {
			set_store_value(motion, $motion = 'safe', $motion);
		}
	}

	return [toggle];
}

class ToggleMotion extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default ToggleMotion;