import './Back.svelte.css';
/* src/components/Back.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	svg_element,
	text
} from "../../_snowpack/pkg/svelte/internal.js";

import { mdiHome } from '../../_snowpack/pkg/@mdi/js.js';

function create_fragment(ctx) {
	let button;
	let svg;
	let path;
	let t;
	let mounted;
	let dispose;

	return {
		c() {
			button = element("button");
			svg = svg_element("svg");
			path = svg_element("path");
			t = text("\n  Back");
			attr(path, "d", mdiHome);
			attr(svg, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg, "viewBox", "0 0 24 24");
			attr(svg, "width", "24px");
			attr(svg, "height", "24px");
			attr(svg, "class", "svelte-1fdfj85");
			attr(button, "class", "svelte-1fdfj85");
		},
		m(target, anchor) {
			insert(target, button, anchor);
			append(button, svg);
			append(svg, path);
			append(button, t);

			if (!mounted) {
				dispose = listen(button, "click", /*click_handler*/ ctx[0]);
				mounted = true;
			}
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self) {
	const click_handler = () => {
		window.location.hash = 'home';
	};

	return [click_handler];
}

class Back extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Back;