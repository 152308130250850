import './MusicBars.svelte.css';
/* src/components/MusicBars.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	space
} from "../../_snowpack/pkg/svelte/internal.js";

import { motion, current } from '../stores.js';

function create_fragment(ctx) {
	let div3;
	let div0;
	let t0;
	let div1;
	let t1;
	let div2;
	let div3_class_value;

	return {
		c() {
			div3 = element("div");
			div0 = element("div");
			t0 = space();
			div1 = element("div");
			t1 = space();
			div2 = element("div");
			attr(div0, "class", "svelte-2x4gzx");
			attr(div1, "class", "svelte-2x4gzx");
			attr(div2, "class", "svelte-2x4gzx");
			attr(div3, "id", "bars");
			attr(div3, "class", div3_class_value = "bars " + /*$motion*/ ctx[0] + " " + (/*$current*/ ctx[1] ? 'animated' : '') + " svelte-2x4gzx");
		},
		m(target, anchor) {
			insert(target, div3, anchor);
			append(div3, div0);
			append(div3, t0);
			append(div3, div1);
			append(div3, t1);
			append(div3, div2);
		},
		p(ctx, [dirty]) {
			if (dirty & /*$motion, $current*/ 3 && div3_class_value !== (div3_class_value = "bars " + /*$motion*/ ctx[0] + " " + (/*$current*/ ctx[1] ? 'animated' : '') + " svelte-2x4gzx")) {
				attr(div3, "class", div3_class_value);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div3);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $motion;
	let $current;
	component_subscribe($$self, motion, $$value => $$invalidate(0, $motion = $$value));
	component_subscribe($$self, current, $$value => $$invalidate(1, $current = $$value));
	return [$motion, $current];
}

class MusicBars extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default MusicBars;