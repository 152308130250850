import './Music.svelte.css';
/* src/components/Music.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	safe_not_equal,
	set_data,
	set_store_value,
	space,
	text,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import MusicBars from './MusicBars.svelte.js';
import { current } from '../stores.js';
import { onMount } from '../../_snowpack/pkg/svelte.js';

function create_if_block(ctx) {
	let br;
	let t0;
	let span;
	let t1;

	return {
		c() {
			br = element("br");
			t0 = text("from ");
			span = element("span");
			t1 = text(/*album*/ ctx[2]);
			attr(span, "class", "svelte-1vjkdtx");
		},
		m(target, anchor) {
			insert(target, br, anchor);
			insert(target, t0, anchor);
			insert(target, span, anchor);
			append(span, t1);
		},
		p(ctx, dirty) {
			if (dirty & /*album*/ 4) set_data(t1, /*album*/ ctx[2]);
		},
		d(detaching) {
			if (detaching) detach(br);
			if (detaching) detach(t0);
			if (detaching) detach(span);
		}
	};
}

function create_fragment(ctx) {
	let section;
	let musicbars;
	let t0;
	let div0;

	let t1_value = (/*$current*/ ctx[3]
	? 'Now listening to'
	: 'Last listened to') + "";

	let t1;
	let t2;
	let div1;
	let span0;
	let t3;
	let t4;
	let span1;
	let t5;
	let t6;
	let current;
	musicbars = new MusicBars({});
	let if_block = /*album*/ ctx[2] && create_if_block(ctx);

	return {
		c() {
			section = element("section");
			create_component(musicbars.$$.fragment);
			t0 = space();
			div0 = element("div");
			t1 = text(t1_value);
			t2 = space();
			div1 = element("div");
			span0 = element("span");
			t3 = text(/*track*/ ctx[1]);
			t4 = text(" by ");
			span1 = element("span");
			t5 = text(/*artist*/ ctx[0]);
			t6 = space();
			if (if_block) if_block.c();
			attr(div0, "class", "smol svelte-1vjkdtx");
			attr(span0, "class", "svelte-1vjkdtx");
			attr(span1, "class", "svelte-1vjkdtx");
			attr(div1, "class", "svelte-1vjkdtx");
			attr(section, "id", "now-playing");
			attr(section, "class", "svelte-1vjkdtx");
		},
		m(target, anchor) {
			insert(target, section, anchor);
			mount_component(musicbars, section, null);
			append(section, t0);
			append(section, div0);
			append(div0, t1);
			append(section, t2);
			append(section, div1);
			append(div1, span0);
			append(span0, t3);
			append(div1, t4);
			append(div1, span1);
			append(span1, t5);
			append(div1, t6);
			if (if_block) if_block.m(div1, null);
			current = true;
		},
		p(ctx, [dirty]) {
			if ((!current || dirty & /*$current*/ 8) && t1_value !== (t1_value = (/*$current*/ ctx[3]
			? 'Now listening to'
			: 'Last listened to') + "")) set_data(t1, t1_value);

			if (!current || dirty & /*track*/ 2) set_data(t3, /*track*/ ctx[1]);
			if (!current || dirty & /*artist*/ 1) set_data(t5, /*artist*/ ctx[0]);

			if (/*album*/ ctx[2]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div1, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i(local) {
			if (current) return;
			transition_in(musicbars.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(musicbars.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(section);
			destroy_component(musicbars);
			if (if_block) if_block.d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $current;
	component_subscribe($$self, current, $$value => $$invalidate(3, $current = $$value));
	let artist = 'loading';
	let track = 'server noises';
	let album = 'yaah';

	onMount(async () => {
		const req = await fetch('https://nowplaying.5ht2.workers.dev');

		if (req.ok) {
			const data = await req.json();
			$$invalidate(0, artist = data.artist);
			$$invalidate(1, track = data.title);
			$$invalidate(2, album = data.album);
			set_store_value(current, $current = data.now_playing, $current);
		}
	});

	return [artist, track, album, $current];
}

class Music extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Music;