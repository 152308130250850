import './PGP.svelte.css';
/* src/pages/PGP.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	create_component,
	create_in_transition,
	create_out_transition,
	destroy_component,
	detach,
	element,
	init,
	insert,
	listen,
	mount_component,
	run_all,
	safe_not_equal,
	space,
	svg_element,
	text,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import { mdiDownload, mdiFingerprint, mdiCheck } from '../../_snowpack/pkg/@mdi/js.js';
import { fly } from '../../_snowpack/pkg/svelte/transition.js';
import Back from '../components/Back.svelte.js';
import copy from './copy.js';

function create_fragment(ctx) {
	let main;
	let div;
	let h2;
	let t1;
	let button0;
	let svg0;
	let path0;
	let t2;
	let t3;
	let button1;
	let svg1;
	let path1;
	let title;
	let t4;
	let t5;
	let br;
	let t6;
	let t7;
	let t8;
	let back;
	let main_intro;
	let main_outro;
	let current;
	let mounted;
	let dispose;
	back = new Back({});

	return {
		c() {
			main = element("main");
			div = element("div");
			h2 = element("h2");
			h2.textContent = "PGP";
			t1 = space();
			button0 = element("button");
			svg0 = svg_element("svg");
			path0 = svg_element("path");
			t2 = text("\n      Verify and download key");
			t3 = space();
			button1 = element("button");
			svg1 = svg_element("svg");
			path1 = svg_element("path");
			title = svg_element("title");
			t4 = text("Copy Fingerprint");
			t5 = text("\n      Copy fingerprint: ");
			br = element("br");
			t6 = space();
			t7 = text(fingerprint);
			t8 = space();
			create_component(back.$$.fragment);
			attr(path0, "d", mdiDownload);
			attr(svg0, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg0, "viewBox", "0 0 24 24");
			attr(svg0, "width", "24px");
			attr(svg0, "height", "24px");
			attr(svg0, "class", "svelte-1d2lsmd");
			attr(button0, "class", "download svelte-1d2lsmd");
			attr(path1, "d", /*copyIcon*/ ctx[0]);
			attr(svg1, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg1, "viewBox", "0 0 24 24");
			attr(svg1, "width", "24px");
			attr(svg1, "height", "24px");
			attr(svg1, "class", "svelte-1d2lsmd");
			attr(button1, "class", "fingerprint svelte-1d2lsmd");
			attr(div, "class", "svelte-1d2lsmd");
			attr(main, "class", "svelte-1d2lsmd");
		},
		m(target, anchor) {
			insert(target, main, anchor);
			append(main, div);
			append(div, h2);
			append(div, t1);
			append(div, button0);
			append(button0, svg0);
			append(svg0, path0);
			append(button0, t2);
			append(div, t3);
			append(div, button1);
			append(button1, svg1);
			append(svg1, path1);
			append(svg1, title);
			append(title, t4);
			append(button1, t5);
			append(button1, br);
			append(button1, t6);
			append(button1, t7);
			append(div, t8);
			mount_component(back, div, null);
			current = true;

			if (!mounted) {
				dispose = [
					listen(button0, "click", /*click_handler*/ ctx[1]),
					listen(button1, "click", /*click_handler_1*/ ctx[2])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (!current || dirty & /*copyIcon*/ 1) {
				attr(path1, "d", /*copyIcon*/ ctx[0]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(back.$$.fragment, local);

			add_render_callback(() => {
				if (!current) return;
				if (main_outro) main_outro.end(1);
				main_intro = create_in_transition(main, fly, { x: -600, duration: 400 });
				main_intro.start();
			});

			current = true;
		},
		o(local) {
			transition_out(back.$$.fragment, local);
			if (main_intro) main_intro.invalidate();
			main_outro = create_out_transition(main, fly, { x: -600, duration: 400 });
			current = false;
		},
		d(detaching) {
			if (detaching) detach(main);
			destroy_component(back);
			if (detaching && main_outro) main_outro.end();
			mounted = false;
			run_all(dispose);
		}
	};
}

const fingerprint = '3189 BA08 A549 B7B6 E738 0760 683B D783 56AC 0425';

async function keyIsValid() {
	try {
		const local = await (await fetch('./pgp/5ht2.asc')).text();
		const git = await (await fetch('https://git.5ht2.me/lmaotrigine/website/-/raw/main/public/pgp/5ht2.asc')).text();
		return local === git;
	} catch {
		return false;
	}
}

async function openKey() {
	if (await keyIsValid()) {
		window.open('./pgp/5ht2.asc');
	} else if (confirm("Uh, the keys don't match... Something suspicious is happening. Or the GitLab instance might be down?\n\n" + 'Do you still want to download the key?\n\n' + "I strongly recommend that you don't!")) {
		window.open('./pgp/5ht2.asc');
	}
}

function instance($$self, $$props, $$invalidate) {
	let copyIcon = mdiFingerprint;

	const click_handler = async () => {
		await openKey();
	};

	const click_handler_1 = () => copy(fingerprint, () => {
		$$invalidate(0, copyIcon = mdiCheck);
	});

	return [copyIcon, click_handler, click_handler_1];
}

class PGP extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default PGP;