function fallback(text) {
  prompt("You might want to upgrade your browser... (Copy this)", text);
}
export default function copy(text, success) {
  if (!navigator.clipboard) {
    fallback(text);
  } else {
    try {
      navigator.clipboard.writeText(text).then(() => {
        success();
      }, () => {
        fallback(text);
      });
    } catch {
      fallback(text);
    }
  }
}
