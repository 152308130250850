import './Canary.svelte.css';
/* src/components/Canary.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	run_all,
	safe_not_equal
} from "../../_snowpack/pkg/svelte/internal.js";

function create_fragment(ctx) {
	let div;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");

			div.innerHTML = `<pre>   __
  &lt;- )
  /( \\
  \\_\\_&gt;
   &quot; &quot;</pre>`;

			attr(div, "id", "canary");
			attr(div, "class", "svelte-mwe4ca");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (!mounted) {
				dispose = [listen(div, "click", toggle), listen(div, "keypress", toggle)];
				mounted = true;
			}
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			run_all(dispose);
		}
	};
}

function toggle() {
	if (window.location.hash === '#canary') {
		window.location.hash = 'home';
	} else {
		window.location.hash = 'canary';
	}
}

function instance($$self) {
	"use strict";
	return [];
}

class Canary extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Canary;