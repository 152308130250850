import './Projects.svelte.css';
/* src/pages/Projects.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	create_component,
	create_in_transition,
	create_out_transition,
	destroy_component,
	destroy_each,
	detach,
	element,
	init,
	insert,
	listen,
	mount_component,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	space,
	svg_element,
	text,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import { onMount } from '../../_snowpack/pkg/svelte.js';
import { fly, fade } from '../../_snowpack/pkg/svelte/transition.js';
import Back from '../components/Back.svelte.js';
import getProjects from './projects.js';
import { mdiSourceFork, mdiStarOutline } from '../../_snowpack/pkg/@mdi/js.js';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[3] = list[i];
	child_ctx[5] = i;
	return child_ctx;
}

// (16:4) {#if projects.length === 0}
function create_if_block(ctx) {
	let p;

	return {
		c() {
			p = element("p");
			p.textContent = "Fetching data from GitHub...";
			attr(p, "class", "loading svelte-1tunk3o");
		},
		m(target, anchor) {
			insert(target, p, anchor);
		},
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

// (20:6) {#each projects as project, i}
function create_each_block(ctx) {
	let div4;
	let h3;
	let t0_value = /*project*/ ctx[3].name + "";
	let t0;
	let t1;
	let p0;
	let t2_value = /*project*/ ctx[3].description + "";
	let t2;
	let t3;
	let div3;
	let div2;
	let div0;
	let svg0;
	let path0;
	let title0;
	let t4;
	let t5;
	let p1;
	let t6_value = /*project*/ ctx[3].stargazers_count + "";
	let t6;
	let t7;
	let div1;
	let svg1;
	let path1;
	let title1;
	let t8;
	let t9;
	let p2;
	let t10_value = /*project*/ ctx[3].forks + "";
	let t10;
	let t11;
	let p3;
	let t12_value = /*project*/ ctx[3].language + "";
	let t12;
	let p3_class_value;
	let t13;
	let div4_tabindex_value;
	let div4_intro;
	let mounted;
	let dispose;

	function keypress_handler(...args) {
		return /*keypress_handler*/ ctx[1](/*project*/ ctx[3], ...args);
	}

	function click_handler() {
		return /*click_handler*/ ctx[2](/*project*/ ctx[3]);
	}

	return {
		c() {
			div4 = element("div");
			h3 = element("h3");
			t0 = text(t0_value);
			t1 = space();
			p0 = element("p");
			t2 = text(t2_value);
			t3 = space();
			div3 = element("div");
			div2 = element("div");
			div0 = element("div");
			svg0 = svg_element("svg");
			path0 = svg_element("path");
			title0 = svg_element("title");
			t4 = text("Stars");
			t5 = space();
			p1 = element("p");
			t6 = text(t6_value);
			t7 = space();
			div1 = element("div");
			svg1 = svg_element("svg");
			path1 = svg_element("path");
			title1 = svg_element("title");
			t8 = text("Forks");
			t9 = space();
			p2 = element("p");
			t10 = text(t10_value);
			t11 = space();
			p3 = element("p");
			t12 = text(t12_value);
			t13 = space();
			attr(path0, "d", mdiStarOutline);
			attr(svg0, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg0, "viewBox", "0 0 24 24");
			attr(svg0, "width", "16px");
			attr(svg0, "height", "16px");
			attr(svg0, "class", "svelte-1tunk3o");
			attr(p1, "class", "svelte-1tunk3o");
			attr(div0, "class", "svelte-1tunk3o");
			attr(path1, "d", mdiSourceFork);
			attr(svg1, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg1, "viewBox", "0 0 24 24");
			attr(svg1, "width", "16px");
			attr(svg1, "height", "16px");
			attr(svg1, "class", "svelte-1tunk3o");
			attr(p2, "class", "svelte-1tunk3o");
			attr(div1, "class", "svelte-1tunk3o");
			attr(div2, "class", "stats svelte-1tunk3o");
			attr(p3, "class", p3_class_value = "language " + /*project*/ ctx[3].language + " svelte-1tunk3o");
			attr(div3, "class", "info svelte-1tunk3o");
			attr(div4, "role", "button");
			attr(div4, "tabindex", div4_tabindex_value = /*i*/ ctx[5] + 1);
			attr(div4, "class", "project svelte-1tunk3o");
		},
		m(target, anchor) {
			insert(target, div4, anchor);
			append(div4, h3);
			append(h3, t0);
			append(div4, t1);
			append(div4, p0);
			append(p0, t2);
			append(div4, t3);
			append(div4, div3);
			append(div3, div2);
			append(div2, div0);
			append(div0, svg0);
			append(svg0, path0);
			append(svg0, title0);
			append(title0, t4);
			append(div0, t5);
			append(div0, p1);
			append(p1, t6);
			append(div2, t7);
			append(div2, div1);
			append(div1, svg1);
			append(svg1, path1);
			append(svg1, title1);
			append(title1, t8);
			append(div1, t9);
			append(div1, p2);
			append(p2, t10);
			append(div3, t11);
			append(div3, p3);
			append(p3, t12);
			append(div4, t13);

			if (!mounted) {
				dispose = [
					listen(div4, "keypress", keypress_handler),
					listen(div4, "click", click_handler)
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty & /*projects*/ 1 && t0_value !== (t0_value = /*project*/ ctx[3].name + "")) set_data(t0, t0_value);
			if (dirty & /*projects*/ 1 && t2_value !== (t2_value = /*project*/ ctx[3].description + "")) set_data(t2, t2_value);
			if (dirty & /*projects*/ 1 && t6_value !== (t6_value = /*project*/ ctx[3].stargazers_count + "")) set_data(t6, t6_value);
			if (dirty & /*projects*/ 1 && t10_value !== (t10_value = /*project*/ ctx[3].forks + "")) set_data(t10, t10_value);
			if (dirty & /*projects*/ 1 && t12_value !== (t12_value = /*project*/ ctx[3].language + "")) set_data(t12, t12_value);

			if (dirty & /*projects*/ 1 && p3_class_value !== (p3_class_value = "language " + /*project*/ ctx[3].language + " svelte-1tunk3o")) {
				attr(p3, "class", p3_class_value);
			}
		},
		i(local) {
			if (!div4_intro) {
				add_render_callback(() => {
					div4_intro = create_in_transition(div4, fade, {});
					div4_intro.start();
				});
			}
		},
		o: noop,
		d(detaching) {
			if (detaching) detach(div4);
			mounted = false;
			run_all(dispose);
		}
	};
}

function create_fragment(ctx) {
	let main;
	let div1;
	let h2;
	let t1;
	let back;
	let t2;
	let t3;
	let div0;
	let main_intro;
	let main_outro;
	let current;
	back = new Back({});
	let if_block = /*projects*/ ctx[0].length === 0 && create_if_block(ctx);
	let each_value = /*projects*/ ctx[0];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			main = element("main");
			div1 = element("div");
			h2 = element("h2");
			h2.textContent = "Projects";
			t1 = space();
			create_component(back.$$.fragment);
			t2 = space();
			if (if_block) if_block.c();
			t3 = space();
			div0 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div0, "class", "projects svelte-1tunk3o");
			attr(div1, "class", "project-container");
			attr(main, "class", "svelte-1tunk3o");
		},
		m(target, anchor) {
			insert(target, main, anchor);
			append(main, div1);
			append(div1, h2);
			append(div1, t1);
			mount_component(back, div1, null);
			append(div1, t2);
			if (if_block) if_block.m(div1, null);
			append(div1, t3);
			append(div1, div0);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div0, null);
				}
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (/*projects*/ ctx[0].length === 0) {
				if (if_block) {
					
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div1, t3);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*window, projects, mdiSourceFork, mdiStarOutline*/ 1) {
				each_value = /*projects*/ ctx[0];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div0, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		i(local) {
			if (current) return;
			transition_in(back.$$.fragment, local);

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			add_render_callback(() => {
				if (!current) return;
				if (main_outro) main_outro.end(1);
				main_intro = create_in_transition(main, fly, { y: 600, duration: 400 });
				main_intro.start();
			});

			current = true;
		},
		o(local) {
			transition_out(back.$$.fragment, local);
			if (main_intro) main_intro.invalidate();
			main_outro = create_out_transition(main, fly, { y: 600, duration: 400 });
			current = false;
		},
		d(detaching) {
			if (detaching) detach(main);
			destroy_component(back);
			if (if_block) if_block.d();
			destroy_each(each_blocks, detaching);
			if (detaching && main_outro) main_outro.end();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let projects = [];

	onMount(async () => {
		$$invalidate(0, projects = await getProjects('lmaotrigine'));
	});

	const keypress_handler = (project, e) => {
		if (e.key === 'Enter') window.open(`${project.html_url}`);
	};

	const click_handler = project => window.open(`${project.html_url}`);
	return [projects, keypress_handler, click_handler];
}

class Projects extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Projects;