function getProjects(user) {
  return new Promise(async (resolve, reject) => {
    let repos = [];
    let page = [];
    let i = 1;
    while (page.length === 30 || repos.length === 0) {
      const res = await fetch(`https://api.github.com/users/${user}/repos?page=${i}`);
      page = await res.json();
      if (!res.ok)
        reject(res.statusText);
      repos = [...repos, page].flat(1);
      i++;
    }
    repos = repos.filter((repo) => !repo.fork && !repo.archived && repo.name !== user && !repo.name.startsWith("."));
    resolve(repos);
  });
}
export default getProjects;
