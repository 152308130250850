import './Accounts.svelte.css';
/* src/pages/Accounts.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	create_component,
	create_in_transition,
	create_out_transition,
	destroy_component,
	detach,
	element,
	init,
	insert,
	listen,
	mount_component,
	run_all,
	safe_not_equal,
	space,
	svg_element,
	text,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import { fly } from '../../_snowpack/pkg/svelte/transition.js';
import Back from '../components/Back.svelte.js';

import {
	mdiDiscord,
	mdiGithub,
	mdiMatrix,
	mdiCheck,
	mdiTwitter,
	mdiSpotify,
	mdiSteam
} from '../../_snowpack/pkg/@mdi/js.js';

import copy from './copy.js';

function create_fragment(ctx) {
	let main;
	let div1;
	let h2;
	let t1;
	let div0;
	let button0;
	let svg0;
	let path0;
	let t2;
	let t3;
	let button1;
	let svg1;
	let path1;
	let t4;
	let t5;
	let button2;
	let svg2;
	let path2;
	let t6;
	let t7;
	let button3;
	let svg3;
	let path3;
	let t8;
	let t9;
	let button4;
	let svg4;
	let path4;
	let t10;
	let t11;
	let button5;
	let svg5;
	let path5;
	let t12;
	let t13;
	let button6;
	let svg6;
	let path6;
	let t14;
	let t15;
	let button7;
	let svg7;
	let path7;
	let t16;
	let t17;
	let button8;
	let t19;
	let back;
	let main_intro;
	let main_outro;
	let current;
	let mounted;
	let dispose;
	back = new Back({});

	return {
		c() {
			main = element("main");
			div1 = element("div");
			h2 = element("h2");
			h2.textContent = "Links";
			t1 = space();
			div0 = element("div");
			button0 = element("button");
			svg0 = svg_element("svg");
			path0 = svg_element("path");
			t2 = text("\n        Copy Discord");
			t3 = space();
			button1 = element("button");
			svg1 = svg_element("svg");
			path1 = svg_element("path");
			t4 = text("\n        Github");
			t5 = space();
			button2 = element("button");
			svg2 = svg_element("svg");
			path2 = svg_element("path");
			t6 = text("\n        Twitter");
			t7 = space();
			button3 = element("button");
			svg3 = svg_element("svg");
			path3 = svg_element("path");
			t8 = text("\n        Spotify");
			t9 = space();
			button4 = element("button");
			svg4 = svg_element("svg");
			path4 = svg_element("path");
			t10 = text("\n        Keybase");
			t11 = space();
			button5 = element("button");
			svg5 = svg_element("svg");
			path5 = svg_element("path");
			t12 = text("\n        Matrix");
			t13 = space();
			button6 = element("button");
			svg6 = svg_element("svg");
			path6 = svg_element("path");
			t14 = text("\n        Steam");
			t15 = space();
			button7 = element("button");
			svg7 = svg_element("svg");
			path7 = svg_element("path");
			t16 = text("\n        AniList");
			t17 = space();
			button8 = element("button");

			button8.innerHTML = `<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#000" width="24px" height="24px" class="svelte-jxeeo2"><rect x="2.75" y="3.30825" width="3.80155" height="14.92115" rx="1" ry="1" class="svelte-jxeeo2"></rect><rect x="8.2818" y="3.30825" width="3.80155" height="14.92115" rx="1" ry="1" class="svelte-jxeeo2"></rect><rect x="15.4554" y="3.17155" width="3.80155" height="14.92115" rx="1" ry="1" transform="translate(-2.34175 5.50675) rotate(-16.9062)" class="svelte-jxeeo2"></rect><rect x="2.75" y="19.5214" width="18.5" height="1.414" rx="1" ry="1" class="svelte-jxeeo2"></rect></svg>
        StoryGraph`;

			t19 = space();
			create_component(back.$$.fragment);
			attr(path0, "d", /*discordIcon*/ ctx[0]);
			attr(svg0, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg0, "viewBox", "0 0 24 24");
			attr(svg0, "width", "32px");
			attr(svg0, "height", "32px");
			attr(svg0, "class", "svelte-jxeeo2");
			attr(button0, "class", "discord svelte-jxeeo2");
			attr(path1, "d", mdiGithub);
			attr(svg1, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg1, "viewBox", "0 0 24 24");
			attr(svg1, "width", "32px");
			attr(svg1, "height", "32px");
			attr(svg1, "class", "svelte-jxeeo2");
			attr(button1, "class", "github svelte-jxeeo2");
			attr(path2, "d", mdiTwitter);
			attr(svg2, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg2, "viewBox", "0 0 24 24");
			attr(svg2, "width", "24px");
			attr(svg2, "height", "24px");
			attr(svg2, "class", "svelte-jxeeo2");
			attr(button2, "class", "svelte-jxeeo2");
			attr(path3, "d", mdiSpotify);
			attr(svg3, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg3, "viewBox", "0 0 24 24");
			attr(svg3, "width", "24px");
			attr(svg3, "height", "24px");
			attr(svg3, "class", "svelte-jxeeo2");
			attr(button3, "class", "svelte-jxeeo2");
			attr(path4, "fill", "#FFF");
			attr(path4, "d", keybase);
			attr(svg4, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg4, "viewBox", "0 0 448 512");
			attr(svg4, "width", "24px");
			attr(svg4, "height", "24px");
			attr(svg4, "class", "svelte-jxeeo2");
			attr(button4, "class", "svelte-jxeeo2");
			attr(path5, "d", mdiMatrix);
			attr(svg5, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg5, "viewBox", "0 0 24 24");
			attr(svg5, "width", "24px");
			attr(svg5, "height", "24px");
			attr(svg5, "class", "svelte-jxeeo2");
			attr(button5, "class", "svelte-jxeeo2");
			attr(path6, "d", mdiSteam);
			attr(svg6, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg6, "viewBox", "0 0 24 24");
			attr(svg6, "width", "24px");
			attr(svg6, "height", "24px");
			attr(svg6, "class", "svelte-jxeeo2");
			attr(button6, "class", "svelte-jxeeo2");
			attr(path7, "d", anilist);
			attr(svg7, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg7, "viewBox", "0 0 24 24");
			attr(svg7, "width", "24px");
			attr(svg7, "height", "24px");
			attr(svg7, "class", "svelte-jxeeo2");
			attr(button7, "class", "svelte-jxeeo2");
			attr(button8, "class", "svelte-jxeeo2");
			attr(div0, "class", "accounts svelte-jxeeo2");
			attr(div1, "class", "container svelte-jxeeo2");
			attr(main, "class", "svelte-jxeeo2");
		},
		m(target, anchor) {
			insert(target, main, anchor);
			append(main, div1);
			append(div1, h2);
			append(div1, t1);
			append(div1, div0);
			append(div0, button0);
			append(button0, svg0);
			append(svg0, path0);
			append(button0, t2);
			append(div0, t3);
			append(div0, button1);
			append(button1, svg1);
			append(svg1, path1);
			append(button1, t4);
			append(div0, t5);
			append(div0, button2);
			append(button2, svg2);
			append(svg2, path2);
			append(button2, t6);
			append(div0, t7);
			append(div0, button3);
			append(button3, svg3);
			append(svg3, path3);
			append(button3, t8);
			append(div0, t9);
			append(div0, button4);
			append(button4, svg4);
			append(svg4, path4);
			append(button4, t10);
			append(div0, t11);
			append(div0, button5);
			append(button5, svg5);
			append(svg5, path5);
			append(button5, t12);
			append(div0, t13);
			append(div0, button6);
			append(button6, svg6);
			append(svg6, path6);
			append(button6, t14);
			append(div0, t15);
			append(div0, button7);
			append(button7, svg7);
			append(svg7, path7);
			append(button7, t16);
			append(div0, t17);
			append(div0, button8);
			append(div1, t19);
			mount_component(back, div1, null);
			current = true;

			if (!mounted) {
				dispose = [
					listen(button0, "click", /*click_handler*/ ctx[1]),
					listen(button1, "click", /*click_handler_1*/ ctx[2]),
					listen(button2, "click", /*click_handler_2*/ ctx[3]),
					listen(button3, "click", /*click_handler_3*/ ctx[4]),
					listen(button4, "click", /*click_handler_4*/ ctx[5]),
					listen(button5, "click", /*click_handler_5*/ ctx[6]),
					listen(button6, "click", /*click_handler_6*/ ctx[7]),
					listen(button7, "click", /*click_handler_7*/ ctx[8]),
					listen(button8, "click", /*click_handler_8*/ ctx[9])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (!current || dirty & /*discordIcon*/ 1) {
				attr(path0, "d", /*discordIcon*/ ctx[0]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(back.$$.fragment, local);

			add_render_callback(() => {
				if (!current) return;
				if (main_outro) main_outro.end(1);
				main_intro = create_in_transition(main, fly, { y: -600, duration: 400, delay: 200 });
				main_intro.start();
			});

			current = true;
		},
		o(local) {
			transition_out(back.$$.fragment, local);
			if (main_intro) main_intro.invalidate();
			main_outro = create_out_transition(main, fly, { y: -600, duration: 400 });
			current = false;
		},
		d(detaching) {
			if (detaching) detach(main);
			destroy_component(back);
			if (detaching && main_outro) main_outro.end();
			mounted = false;
			run_all(dispose);
		}
	};
}

const discord = '5ht2';
const keybase = 'M286.17 419a18 18 0 1 0 18 18 18 18 0 0 0-18-18zm111.92-147.6c-9.5-14.62-39.37-52.45-87.26-73.71q-9.1-4.06-18.38-7.27a78.43 78.43 0 0 0-47.88-104.13c-12.41-4.1-23.33-6-32.41-5.77-.6-2-1.89-11 9.4-35L198.66 32l-5.48 7.56c-8.69 12.06-16.92 23.55-24.34 34.89a51 51 0 0 0-8.29-1.25c-41.53-2.45-39-2.33-41.06-2.33-50.61 0-50.75 52.12-50.75 45.88l-2.36 36.68c-1.61 27 19.75 50.21 47.63 51.85l8.93.54a214 214 0 0 0-46.29 35.54C14 304.66 14 374 14 429.77v33.64l23.32-29.8a148.6 148.6 0 0 0 14.56 37.56c5.78 10.13 14.87 9.45 19.64 7.33 4.21-1.87 10-6.92 3.75-20.11a178.29 178.29 0 0 1-15.76-53.13l46.82-59.83-24.66 74.11c58.23-42.4 157.38-61.76 236.25-38.59 34.2 10.05 67.45.69 84.74-23.84.72-1 1.2-2.16 1.85-3.22a156.09 156.09 0 0 1 2.8 28.43c0 23.3-3.69 52.93-14.88 81.64-2.52 6.46 1.76 14.5 8.6 15.74 7.42 1.57 15.33-3.1 18.37-11.15C429 443 434 414 434 382.32c0-38.58-13-77.46-35.91-110.92zM142.37 128.58l-15.7-.93-1.39 21.79 13.13.78a93 93 0 0 0 .32 19.57l-22.38-1.34a12.28 12.28 0 0 1-11.76-12.79L107 119c1-12.17 13.87-11.27 13.26-11.32l29.11 1.73a144.35 144.35 0 0 0-7 19.17zm148.42 172.18a10.51 10.51 0 0 1-14.35-1.39l-9.68-11.49-34.42 27a8.09 8.09 0 0 1-11.13-1.08l-15.78-18.64a7.38 7.38 0 0 1 1.34-10.34l34.57-27.18-14.14-16.74-17.09 13.45a7.75 7.75 0 0 1-10.59-1s-3.72-4.42-3.8-4.53a7.38 7.38 0 0 1 1.37-10.34L214 225.19s-18.51-22-18.6-22.14a9.56 9.56 0 0 1 1.74-13.42 10.38 10.38 0 0 1 14.3 1.37l81.09 96.32a9.58 9.58 0 0 1-1.74 13.44zM187.44 419a18 18 0 1 0 18 18 18 18 0 0 0-18-18z';
const anilist = 'M6.361 2.943L0 21.056h4.942l1.077-3.133H11.4l1.052 3.133H22.9c.71 0 1.1-.392 1.1-1.101V17.53c0-.71-.39-1.101-1.1-1.101h-6.483V4.045c0-.71-.392-1.102-1.101-1.102h-2.422c-.71 0-1.101.392-1.101 1.102v1.064l-.758-2.166zm2.324 5.948l1.688 5.018H7.144z';

function instance($$self, $$props, $$invalidate) {
	let discordIcon = mdiDiscord;

	const click_handler = () => {
		copy(discord, () => $$invalidate(0, discordIcon = mdiCheck));
	};

	const click_handler_1 = () => window.open('https://github.com/lmaotrigine');
	const click_handler_2 = () => window.open('https://twitter.com/lmao_trigine');
	const click_handler_3 = () => window.open('https://open.spotify.com/user/21vvypncqlcy4oqnwjytb5e2i');
	const click_handler_4 = () => window.open('https://keybase.io/darthshittious');
	const click_handler_5 = () => window.open('https://matrix.to/#/@.:5ht2.me');
	const click_handler_6 = () => window.open('https://steamcommunity.com/id/rasagiline/');
	const click_handler_7 = () => window.open('https://anilist.co/user/lmaotrigine');
	const click_handler_8 = () => window.open('https://app.thestorygraph.com/profile/oxazepam');

	return [
		discordIcon,
		click_handler,
		click_handler_1,
		click_handler_2,
		click_handler_3,
		click_handler_4,
		click_handler_5,
		click_handler_6,
		click_handler_7,
		click_handler_8
	];
}

class Accounts extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Accounts;