import './Home.svelte.css';
/* src/Home.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	component_subscribe,
	create_component,
	create_in_transition,
	create_out_transition,
	destroy_component,
	detach,
	element,
	init,
	insert,
	listen,
	mount_component,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	space,
	svg_element,
	text,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import { fade } from '../_snowpack/pkg/svelte/transition.js';
import { cubicIn, linear } from '../_snowpack/pkg/svelte/easing.js';

import {
	mdiChevronDoubleUp,
	mdiChevronDoubleRight,
	mdiChevronDoubleDown,
	mdiChevronDoubleLeft
} from '../_snowpack/pkg/@mdi/js.js';

import { location as _location } from './stores.js';
import Music from './components/Music.svelte.js';

function create_if_block(ctx) {
	let p;
	let t0;
	let t1;
	let t2;
	let p_intro;

	return {
		c() {
			p = element("p");
			t0 = text("\"");
			t1 = text(/*$_location*/ ctx[0]);
			t2 = text("\" doesn't exist, so I've brought you back home.");
		},
		m(target, anchor) {
			insert(target, p, anchor);
			append(p, t0);
			append(p, t1);
			append(p, t2);
		},
		p(ctx, dirty) {
			if (dirty & /*$_location*/ 1) set_data(t1, /*$_location*/ ctx[0]);
		},
		i(local) {
			if (!p_intro) {
				add_render_callback(() => {
					p_intro = create_in_transition(p, fade, { duration: 0, delay: 300 });
					p_intro.start();
				});
			}
		},
		o: noop,
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

function create_fragment(ctx) {
	let nav;
	let div0;
	let h1;
	let t1;
	let h2;
	let t3;
	let music;
	let t4;
	let t5;
	let div1;
	let button0;
	let t6;
	let svg0;
	let path0;
	let t7;
	let div2;
	let button1;
	let t8;
	let svg1;
	let path1;
	let t9;
	let div3;
	let button2;
	let t10;
	let svg2;
	let path2;
	let t11;
	let div4;
	let button3;
	let svg3;
	let path3;
	let t12;
	let nav_intro;
	let nav_outro;
	let current;
	let mounted;
	let dispose;
	music = new Music({});
	let if_block = /*$_location*/ ctx[0] !== 'home' && /*$_location*/ ctx[0] && create_if_block(ctx);

	return {
		c() {
			nav = element("nav");
			div0 = element("div");
			h1 = element("h1");
			h1.textContent = "Hello!";
			t1 = space();
			h2 = element("h2");
			h2.textContent = "I'm Isis.";
			t3 = space();
			create_component(music.$$.fragment);
			t4 = space();
			if (if_block) if_block.c();
			t5 = space();
			div1 = element("div");
			button0 = element("button");
			t6 = text("Projects\n      ");
			svg0 = svg_element("svg");
			path0 = svg_element("path");
			t7 = space();
			div2 = element("div");
			button1 = element("button");
			t8 = text("PGP\n      ");
			svg1 = svg_element("svg");
			path1 = svg_element("path");
			t9 = space();
			div3 = element("div");
			button2 = element("button");
			t10 = text("Accounts\n      ");
			svg2 = svg_element("svg");
			path2 = svg_element("path");
			t11 = space();
			div4 = element("div");
			button3 = element("button");
			svg3 = svg_element("svg");
			path3 = svg_element("path");
			t12 = text("About");
			attr(h1, "class", "svelte-tosiuu");
			attr(h2, "class", "svelte-tosiuu");
			attr(path0, "d", mdiChevronDoubleUp);
			attr(svg0, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg0, "viewBox", "0 0 24 24");
			attr(svg0, "width", "24px");
			attr(svg0, "height", "24px");
			attr(svg0, "class", "svelte-tosiuu");
			attr(button0, "class", "svelte-tosiuu");
			attr(div1, "class", "up nav svelte-tosiuu");
			attr(path1, "d", mdiChevronDoubleRight);
			attr(svg1, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg1, "viewBox", "0 0 24 24");
			attr(svg1, "width", "24px");
			attr(svg1, "height", "24px");
			attr(svg1, "class", "svelte-tosiuu");
			attr(button1, "class", "svelte-tosiuu");
			attr(div2, "class", "right nav svelte-tosiuu");
			attr(path2, "d", mdiChevronDoubleDown);
			attr(svg2, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg2, "viewBox", "0 0 24 24");
			attr(svg2, "width", "24px");
			attr(svg2, "height", "24px");
			attr(svg2, "class", "svelte-tosiuu");
			attr(button2, "class", "svelte-tosiuu");
			attr(div3, "class", "down nav svelte-tosiuu");
			attr(path3, "d", mdiChevronDoubleLeft);
			attr(svg3, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg3, "viewBox", "0 0 24 24");
			attr(svg3, "width", "24px");
			attr(svg3, "height", "24px");
			attr(svg3, "class", "svelte-tosiuu");
			attr(button3, "class", "svelte-tosiuu");
			attr(div4, "class", "left nav svelte-tosiuu");
			attr(nav, "class", "svelte-tosiuu");
		},
		m(target, anchor) {
			insert(target, nav, anchor);
			append(nav, div0);
			append(div0, h1);
			append(div0, t1);
			append(div0, h2);
			append(div0, t3);
			mount_component(music, div0, null);
			append(div0, t4);
			if (if_block) if_block.m(div0, null);
			append(nav, t5);
			append(nav, div1);
			append(div1, button0);
			append(button0, t6);
			append(button0, svg0);
			append(svg0, path0);
			append(nav, t7);
			append(nav, div2);
			append(div2, button1);
			append(button1, t8);
			append(button1, svg1);
			append(svg1, path1);
			append(nav, t9);
			append(nav, div3);
			append(div3, button2);
			append(button2, t10);
			append(button2, svg2);
			append(svg2, path2);
			append(nav, t11);
			append(nav, div4);
			append(div4, button3);
			append(button3, svg3);
			append(svg3, path3);
			append(button3, t12);
			current = true;

			if (!mounted) {
				dispose = [
					listen(button0, "click", /*click_handler*/ ctx[1]),
					listen(button1, "click", /*click_handler_1*/ ctx[2]),
					listen(button2, "click", /*click_handler_2*/ ctx[3]),
					listen(button3, "click", /*click_handler_3*/ ctx[4])
				];

				mounted = true;
			}
		},
		p(new_ctx, [dirty]) {
			ctx = new_ctx;

			if (/*$_location*/ ctx[0] !== 'home' && /*$_location*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*$_location*/ 1) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(div0, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i(local) {
			if (current) return;
			transition_in(music.$$.fragment, local);
			transition_in(if_block);

			add_render_callback(() => {
				if (!current) return;
				if (nav_outro) nav_outro.end(1);
				nav_intro = create_in_transition(nav, fade, { duration: 800, easing: cubicIn });
				nav_intro.start();
			});

			current = true;
		},
		o(local) {
			transition_out(music.$$.fragment, local);
			if (nav_intro) nav_intro.invalidate();
			nav_outro = create_out_transition(nav, fade, { duration: 100, easing: linear });
			current = false;
		},
		d(detaching) {
			if (detaching) detach(nav);
			destroy_component(music);
			if (if_block) if_block.d();
			if (detaching && nav_outro) nav_outro.end();
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $_location;
	component_subscribe($$self, _location, $$value => $$invalidate(0, $_location = $$value));
	const click_handler = () => window.location.hash = '#projects';
	const click_handler_1 = () => window.location.hash = '#pgp';
	const click_handler_2 = () => window.location.hash = '#accounts';
	const click_handler_3 = () => window.location.hash = '#about';
	return [$_location, click_handler, click_handler_1, click_handler_2, click_handler_3];
}

class Home extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Home;